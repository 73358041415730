@import "../variables";
@import "../functions";
@import "./circleCard";
@import "../animation";

@mixin content {
  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    @include flexDisplay(row, space-between, center);
    flex-wrap: wrap;
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
    .profile {
      position: relative;
      max-width: 600px;
      transition: ease-in-out 0.5s;
      transform: translateX(0);
      animation: aboutLeftComein 1s;
      .profile-img {
        @include flexDisplay;
        margin: 30px 0 50px;
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          border: $imgBorder;
          box-shadow: $imgBoxShadow;
          background: $imgBgColor;
          backdrop-filter: blur(2px);
        }
      }
      h2 {
        font-size: 1.8em;
        font-weight: 800;
        letter-spacing: 2px;
        margin: 20px 0;
      }
      p {
        font-size: 1em;
        font-weight: 380;
        line-height: 1.3em;
        letter-spacing: 1px;
        color: $textColour;
        margin: 10px 0 20px;
      }
      .play {
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        color: $textColour;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 1.2em;
        margin: 20px 0 0;
        cursor: pointer;
        transition: 0.5s;
        transform-origin: left;
        img {
          margin-right: 10px;
          max-width: 50px;
        }
        &:hover {
          letter-spacing: 3px;
        }
      }
    }
    .skills {
      position: relative;
      max-width: 600px;
      @include flexDisplay(column, space-around, flex-start);
      @include circleCard;
      transition: ease-in-out 0.5s;
      animation: aboutRightComein 2s;
    }
  }
}

@include aboutLeftComein;
@include aboutRightComein;
