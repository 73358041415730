@import "../variables";
@import "../functions";

@mixin bannerSudo {
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 50px;
    width: 400px;
    background: $primaryColor;
    transition: 1s;
    z-index: 10;
  }
  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    height: 15px;
    width: 400px;
    background: $secondaryColor;
    transition: 1s;
  }
}

@mixin bannerSudoHover {
  &.active {
    &::before {
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: $bgColour;
    }
    &::after {
      bottom: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: $bgColour;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    h2 {
      transform: translateY(-100%);
      visibility: hidden;
      opacity: 0;
      transform-origin: top;
    }
    .btn {
      transform: scale(0);
      transform-origin: left;
    }
    ul.sci {
      transform: scale(0);
      transform-origin: left;
    }
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
}
