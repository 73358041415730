@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import "./scss/variables";
@import "./scss/functions";
@import "./scss/home/menu";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  position: relative; 
  @include menu;
}
