@import "../variables";
@import "../functions";
@import "../animation";
@import "./contentCard";
@import "./animatedCard";

@mixin backend {
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $bgBefColour;
    transition: ease-in-out 0.5s;
    animation: backendLeftBgComein ease-in-out 1s;
  }
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $bgAftColour;
    transition: ease-in-out 0.5s;
    animation: backendRightBgComein ease-in-out 1s;
  }
  &.active {
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    .left {
      transform: translateX(-500%);
      opacity: 0;
      visibility: hidden;
    }
    .right {
      transform: translateX(500%);
      opacity: 0;
      visibility: hidden;
    }
    &::before {
      transform: translateX(-500%);
      opacity: 0;
      visibility: hidden;
    }
    &::after{
      transform: translateX(500%);
      opacity: 0;
      visibility: hidden;
    }
  }
  .container {
    position: relative;
    @include flexDisplay(row, space-between, center);
    flex-wrap: wrap;
    .left {
      position: relative;
      z-index: 12;
      max-width: 580px;
      @include flexDisplay(column, center, center);
      @include contentCard;
      transition: ease-in-out 0.5s;
      animation: backendLeftBgComein ease-in-out 1s;
    }
    .right {
      position: relative;
      z-index: 12;
      max-width: 580px;
      transition: ease-in-out 0.5s;
      @include animatedCard;
      animation: backendRightBgComein ease-in-out 1s;
    }
  }
}

@include backendLeftBgComein;
@include backendRightBgComein;
