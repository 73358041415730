@import "../variables";
@import "../functions";
@import "../animation";

@mixin circleCard {
  .card {
    position: relative;
    height: 160px;
    padding: 0 20px;
    margin-top: 16px;
    @include flexDisplay(row, center, center);
    background: $cardBgColor;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    transition: ease-in-out 0.5s;
    &:nth-child(1) {
      max-width: 600px;
      animation: aboutCardComein1 ease-in-out 1s;
      transform: skewX(45deg) translateX($cardOneEnd);
    }
    &:nth-child(2) {
      max-width: 600px;
      animation: aboutCardComein2 ease-in-out 1.5s;
      transform: skewX(45deg) translateX($cardTwoEnd);
    }
    &:nth-child(3) {
      max-width: 600px;
      animation: aboutCardComein3 ease-in-out 2s;
      transform: skewX(45deg) translateX($cardThreeEnd);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -50%;
      width: 100%;
      height: 100%;
      background: $cardBeforeColor;
      pointer-events: none;
      z-index: 1;
    }
    .box {
      transform: skewX(-45deg);
      padding: 0 10px 0 30px;
      .percentage {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: inset 0 0 50px #000;
        background: $percentageBgColor;
        z-index: 10;
        .number {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include flexDisplay;
          border-radius: 50%;
          h2 {
            color: $cardTextColor;
            font-weight: 700;
            font-size: 40px;
            transition: 0.5s;
            span {
              font-size: 24px;
              color: $cardTextColor;
              transition: 0.5s;
            }
          }
        }
        @include svgSetting;
      }
    }
    .content {
      transform: skewX(-45deg);
      padding: 0 30px;
      max-width: 300px;
      .text {
        position: relative;
        color: $cardTextColor;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 1px;
        transition: 0.5s;
      }
      p {
        color: $cardTextColor;
        font-weight: 400;
        letter-spacing: 1px;
        transition: 0.5s;
        text-align: left;
      }
    }

    &:hover {
      box-shadow: 0 15px 35px $shadowColor;
      &:nth-child(1) {
        transform: skewX(45deg) translateX($cardOneEndHover) translateY(-10px);
      }
      &:nth-child(2) {
        transform: skewX(45deg) translateX($cardTwoEndHover) translateY(-10px);
      }
      &:nth-child(3) {
        transform: skewX(45deg) translateX($cardThreeEndHover) translateY(-10px);
      }
      .percentage {
        .number {
          h2 {
            font-size: 50px;
            color: $textHoverColor;
            span {
              color: $textHoverColor;
            }
          }
        }
      }
      .text {
        color: $textHoverColor;
      }
      P {
        color: $textHoverColor;
      }
    }
  }
}

@include aboutCardComein1;
@include aboutCardComein2;
@include aboutCardComein3;
