@import "../variables";
@import "../functions";
@import "../animation";

@mixin contentCard {
  .contentCard {
    transition: ease-in-out 0.5s;
    margin-bottom: 20px;
    @include flexDisplay;
    .icon {
      font-size: 3em;
      padding: 30px 50px 30px 0;
      i{
        width: 42px;
      }
    }
    .title {
      font-size: 1.8em;
      margin-bottom: 10px;
      letter-spacing: 3px;
    }
    p {
      letter-spacing: 1px;
      font-weight: 300;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}
