@import "../variables";
@import "../functions";
@import "../animation";

@mixin container {
  .container {
    z-index: 10;
    position: relative;
    @include flexDisplay(row, center, center);
    flex-wrap: wrap;
    transition: ease-in-out 0.8s;
    animation: containerAnimation ease-in-out 1s;
    .form-box {
      position: relative;
      width: 650px;
      .box {
        animation: bgAnimate 5s ease-in-out infinite;
        position: relative;
        padding: 50px;
        width: 650px;
        height: 500px;
        @include flexDisplay;
        background: $shadeColor;
        border-radius: 6px;
        box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(25px);
        transition: ease-in-out 0.5s;
        &::after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          background: $boxAfterBg;
          border-radius: 5px;
          pointer-events: none;
        }
        .form {
          position: relative;
          width: 90%;
          h2 {
            color: $textColour;
            font-weight: 600;
            letter-spacing: 2px;
            margin-bottom: 30px;
          }
          p {
            display: block;
            color: $textColour;
            font-weight: 300;
            font-size: 0.9em;
            letter-spacing: 1px;
            transition: ease-in-out 0.5s;
          }
          .inputBox {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            .icon {
              position: absolute;
              width: 30px;
              height: 30px;
              top: 5px;
              left: 10px;
              i {
                padding: 5px;
              }
            }
            input {
              width: 100%;
              outline: none;
              border: 1px solid rgba(225, 225, 225, 0.2);
              border-radius: 6px;
              background: transparent;
              padding: 8px 10px;
              padding-left: 40px;
              color: $textColour;
              font-size: 16px;
              font-weight: 300;
              box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2);
              &::placeholder {
                color: $textColour;
              }
              &.submitBtn {
                background: $textColour;
                color: #111111;
                max-width: 100px;
                padding: 8px 10px;
                box-shadow: none;
                font-weight: 500;
                letter-spacing: 1px;
                cursor: pointer;
              }
            }
            textarea {
              width: 100%;
              outline: none;
              border: 1px solid rgba(225, 225, 225, 0.2);
              border-radius: 6px;
              background: transparent;
              padding: 8px 10px;
              padding-left: 40px;
              color: #ffffff;
              font-size: 16px;
              font-weight: 300;
              box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2);
              &::placeholder {
                color: #ffffff;
              }
            }
          }
        }
      }
    }

    .info {
      max-width: 580px;
      height: 500px;
      padding: 30px 0 20px 30px;
      @include flexDisplay(column, space-between, flex-start);
      h2 {
        margin-top: 15px;
        letter-spacing: 2px;
      }
      p {
        margin-top: 8px;
        letter-spacing: 1px;
        font-weight: 300;
        i {
          padding-right: 3px;
          text-align: left;
          width: 20px;
        }
        a {
          text-decoration: none;
          color: $textColour;
          cursor: pointer;
        }
      }
    }
    @include sciIcon;
    ul.sci {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }
}

@include containerAnimation;
