@import "../variables";
@import "../functions";
@import "../animation";

@mixin data {
  &.active {
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    .title {
      transform: translateY(-1000%);
      transform-origin: bottom;
      visibility: hidden;
      opacity: 0;
    }
    .dataCard {
      &:nth-child(1) {
        transform: translateX(-500%);
      }
      &:nth-child(3) {
        transform: translateY(-500%);
      }
      &:nth-child(5) {
        transform: translateY(500%);
      }
      &:nth-child(7) {
        transform: translateX(500%);
      }
    }
  }
  .title {
    width: 100%;
    margin: 0 0 50px;
    text-align: center;
    transition: 0.5s;
    animation: cardTitleAnimation ease-in-out 1.5s;
    @include flexDisplay(column, center,center);
    h1{
      font-size: 2.5em;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }
    p{
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 1px;
      width: 80%
    }
  }
}

@include cardTitleAnimation;
