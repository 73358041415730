@import "../variables";
@import "../functions";
@import "../animation";
@import "container";

@mixin contact {
  &::before {
    @include bgEffect;
    transition: ease-in-out 0.5s;
    animation: contactBgComein ease-in-out 1s;
  }
  &::after {
    @include bgEffect($bgAfterColour, 20px);
    animation: bgAnimate 5s ease-in-out infinite;
    transition: ease-in-out 0.5s;
  }
  &.active {
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    &::before {
      transform: scale(0);
      transform-origin: top left;
    }
    &::after {
      transform: scale(0);
      transform-origin: left;
    }
    .container {
      transform: translateY(-500%);
    }
  }

  @include container;
}

@include bgAnimate;
@include contactBgComein;
