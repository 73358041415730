@import "../variables";
@import "../functions";

@mixin sciIcon {
  ul.sci {
    position: absolute;
    bottom: 15px;
    left: 100px;
    display: flex;
    transition: 0.5s;
    transform-origin: right;
    li {
      position: relative;
      list-style: none;
      margin: 10px;
      overflow: hidden !important;
      padding: 10px 0 0;
      a {
        position: relative;
        width: 50px;
        height: 50px;
        overflow: hidden !important;
        @include flexDisplay;
        border: $borderPrimary;
        border-radius: 10px;
        text-decoration: none;
        color: $textColour;
        font-size: 2em;
        border-right: $borderLight;
        border-bottom: $borderLight;
        box-shadow: $boxShadow;
        backdrop-filter: blur(2px);
        transition: 0.5s;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          height: 100%;
          width: 50px;
          background: $boxBg;
          transform: skewX(45deg) translateX(150px);
          transition: 0.5s;
        }
        &:hover {
          transform: translateY(-10px);
          overflow: hidden !important;
          &::before {
            transform: skewX(45deg) translateX(-150px);
            transition: 0.5s;
          }
        }
      }
    }
  }
}
