@import "../variables";
@import "../functions";

@mixin menu {
  .menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include flexDisplay(column, center, center);
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    // transition-delay: 0.3s;
    li {
      list-style: none;
      margin-bottom: 10px;
      a {
        text-decoration: none;
        color: $textColour;
        font-size: 2em;
        transition: 0.2s;
        &:hover {
          color: $primaryColor;
        }
      }
    }
    .copyright{
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translateX(-50%);
      color: $textColour;
    }
  }
}
