@import "./variables";
@import "./functions";

@mixin media($breakPoint: map-get($breakPoints, tablet)) {
  @media (max-width: $breakPoint) {
    @include appSm();
  }
}

@mixin appSm {
  @include homeSm;
  @include aboutSm;
  @include frontendSm;
  @include backendSm;
  @include dataAnalyticsSm;
  @include contactSm;
}

@mixin homeSm {
  .banner {
    header {
      padding: 20px 40px;
    }
    &::before {
      height: 40px;
      width: 300px;
    }
    &::after {
      height: 20px;
      width: 200px;
    }
    h2 {
      font-size: 18vw;
    }
    .btn {
      margin: 30px;
    }
    ul.sci {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@mixin aboutSm {
  .about {
    header {
      padding: 20px 40px;
    }
    padding: 80px 40px;
    .content {
      @include flexDisplay(column, space-between, center);
      .skills {
        margin-top: 50px;
        .card {
          height: 200px;
          width: 100%;
          &:nth-child(1) {
            animation: aboutCardComein1 ease-in-out 1s;
            transform: skewX(0) translateX(0);
          }
          &:nth-child(2) {
            animation: aboutCardComein2 ease-in-out 1.5s;
            transform: skewX(0) translateX(0);
          }
          &:nth-child(3) {
            animation: aboutCardComein3 ease-in-out 2s;
            transform: skewX(0) translateX(0);
          }
          &:hover {
            box-shadow: 0 15px 35px $shadowColor;
            &:nth-child(1) {
              transform: skewX(0) translateX(0) translateY(0);
            }
            &:nth-child(2) {
              transform: skewX(0) translateX(0) translateY(0);
            }
            &:nth-child(3) {
              transform: skewX(0) translateX(0) translateY(0);
            }
          }
          .box {
            transform: skewX(0);
            padding: 0;
          }
          .content {
            padding: 0 0 0 20px;
            transform: skewX(0);
            p {
              display: none;
            }
          }
        }
      }
    }
    .trailer {
      video {
        max-width: 90%;
      }
    }
  }
}

@mixin frontendSm {
  .frontend {
    header {
      padding: 20px 40px;
    }
    padding: 80px 0 0;
    .container {
      display: grid;
      /*Create 2 column and each column take up 1 fragment equally*/
      grid-template-columns: repeat(2, 1fr);
      .box {
        &:nth-of-type(1) {
          grid-column: 1/3;
          animation: boxAnimation ease-in-out 2.7s;
        }
        &:nth-of-type(2) {
          grid-column: 1/2;
          animation: boxAnimation ease-in-out 2.4s;
        }
        &:nth-of-type(3) {
          grid-column: 2/3;
          animation: boxAnimation ease-in-out 2.1s;
        }
        &:nth-of-type(4) {
          grid-column: 1/3;
          animation: boxAnimation ease-in-out 1.8s;
        }
        &:nth-of-type(5) {
          grid-column: 1/3;
          animation: boxAnimation ease-in-out 1.5s;
        }
        &:nth-of-type(6) {
          grid-column: 1/2;
          animation: boxAnimation ease-in-out 1.2s;
        }
        &:nth-of-type(7) {
          grid-column: 2/3;
          animation: boxAnimation ease-in-out 0.9s;
        }
        &:nth-of-type(8) {
          grid-column: 1/3;
          animation: boxAnimation ease-in-out 0.6s;
        }
        &:nth-of-type(9) {
          grid-column: 1/3;
          animation: boxAnimation ease-in-out 0.3s;
        }
      }
    }
  }
}

@mixin backendSm {
  .backend {
    padding: 100px 30px;
    header {
      padding: 20px 40px;
    }
    &::before {
      height: 100vh;
    }
    &::after {
      height: 100%;
    }
    .container {
      @include flexDisplay(column, space-between, center);
      .left {
        .contentCard {
          margin-bottom: 30px;
          .icon {
            font-size: 2.5em;
            padding: 20px 40px 20px 0;
            i {
              width: 30px;
            }
          }
        }
      }
      .right {
        .animatedCard {
          height: 300px;
          .content {
            h2 {
              font-size: 6em;
              top: -5%;
            }
          }
        }
      }
    }
  }
}

@mixin dataAnalyticsSm {
  .data-analytics {
    header {
      padding: 20px 40px;
    }
    padding: 80px 40px;
    @include flexDisplay(column, center, center);
    .content {
      @include flexDisplay(row, center, center);
      flex-wrap: wrap;
      .dataCardModal {
        max-width: 90%;
        padding: 30px;
        h2 {
          font-size: 28px;
          margin: 20px 0;
        }
        .close {
          width: 30px;
          height: 30px;
        }
      }
    }
    .title {
      margin: 30px 0 30px;
      h1 {
        font-size: 2em;
      }
      p {
        text-align: center;
        width: 100%;
      }
    }
  }
}

@mixin contactSm {
  .contact {
    @include flexDisplay(column, center, center);
    header {
      padding: 20px 40px;
    }
    padding: 100px 0 0;
    .container {
      @include flexDisplay(column, center, center);
      .form-box {
        @include flexDisplay(column, center, center);
        width: 100%;
        .box {
          padding: 30px 0;
          width: 90%;
        }
      }
      .info {
        padding: 30px 40px;
        max-width: 400px;
        height: 560px;
      }
    }
  }
}
