// media break points
$breakPoints: (
  tablet: 991px,
  mobile: 600px,
);

//Home Page
//banner
//#e80202; #350048; rgba(53, 0, 72, 0.1);
$themeColor: #e80202;
$bgColour: #151415;
$textColour: #ffffff;
$bgTextColor: rgba(225, 225, 225, 0.2);
$shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
$titleBgColor: #f88604;

//banner button
$btnBgColour: rgba(225, 225, 225, 0.1);
$btnShadow: 0 15px 35px rgba(0, 0, 0, 0.3);
$borderEdge: 1px solid rgba(225, 225, 225, 0.1);
$btnEffect: linear-gradient(to left, rgba(225, 225, 225, 0.15), transparent);
$btnDeco: #e80202;

//menu
$primaryColor: #e80202;
$secondaryColor: #aeff0f;
$secondaryTextColor: #111111;

//sci icon
$sectionBg: linear-gradient(to bottom, #ff4f8b, #dff1ff);
$borderPrimary: 1px solid rgba(225, 225, 225, 0.5);
$borderLight: 1px solid rgba(225, 225, 225, 0.2);
$boxShadow: 0 5px 45px rgba(0, 0, 0, 0.1);
$boxBg: rgba(225, 225, 225, 0.5);

//About Page
//about me image
$imgBorder: 5px solid rgba(225, 225, 225, 0.5);
$imgBoxShadow: 0 16px 66px rgba(0, 0, 0, 0.2);
$imgBgColor: rgba(225, 225, 225, 0.2);

//circle card
$cardBgColor: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b);
$circleBgColor: #191919;
$firstCircleColor: #00ff43;
$sencondCircleColor: #00a1ff;
$thirdCircleColor: #ff04f7;
$shadowColor: rgba(0, 0, 0, 0.5);
$cardBeforeColor: rgba(225, 225, 225, 0.03);
$percentageBgColor: #222;
$cardTextColor: #777;
$textHoverColor: #ffffff;

//circle card animation x movement
$cardOneEnd: -236px;
$cardTwoEnd: -60px;
$cardThreeEnd: 116px;

$cardOneEndHover: -246px;
$cardTwoEndHover: -70px;
$cardThreeEndHover: 106px;

// data card
$dataCardBg: rgba(0, 0, 0, 0.6);
$dataCardBg2: rgba(225, 225, 225, 0.5);
$borderColor: #060c21;
$wmColor: rgba(225, 225, 225, 0.2);
$shadeColor: rgba(225, 225, 225, 0.1);
$blurColor1: linear-gradient(315deg, #ff0057, #e64a19);
$blurColor2: linear-gradient(315deg, #e80202, #ffc107);
$blurColor3: linear-gradient(315deg, #e91e63, #5602ff);
$blurColor4: linear-gradient(315deg, #e64a19, #e80202);

// brick box
$boxBg: rgba(225, 225, 225, 0.3);
$themeBoxBg: rgba(232, 2, 2, 0.9);
$thirdBoxBg: rgba(174, 255, 15, 0.9);
$boxAfterBg: linear-gradient(
  to bottom,
  rgba(225, 225, 225, 0.3) 0%,
  rgba(225, 225, 225, 0.1) 15%,
  transparent 50%,
  transparent 85%,
  rgba(225, 225, 225, 0.3) 100%
);
$boxPrimaryBg: linear-gradient(315deg, #aeff0f, #e80202, #aeff0f);
$boxSecondaryBg: linear-gradient(315deg, #aeff0f, #e80202);
$boxThirdBg: linear-gradient(315deg, #e80202, #aeff0f);

// backend page animated card

$cardBgColour: rgba(225, 225, 225, 0.1);
$cardBorder: 1px solid rgba(225, 225, 225, 0.5);
$wmColour: rgba(225, 225, 225, 0.05);
$titleColour: rgba(225, 225, 225, 0.5);

$bgBefColour: linear-gradient(
  150deg,
  #e80202,
  #e80202 30%,
  #151415 30%,
  #151415
);

$bgAftColour: linear-gradient(
  150deg,
  transparent,
  transparent 80%,
  #e80202 80%,
  #e80202
);

// contact page
$bgBeforeColour: linear-gradient(
  45deg,
  #e80202,
  #e80202 60%,
  #151415 60%,
  #151415
);

$bgAfterColour: linear-gradient(
  160deg,
  transparent,
  transparent 50%,
  #151415 50%,
  #151415
);
