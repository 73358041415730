@import "../variables";
@import "../functions";

@mixin header {
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px;
    .logo {
      font-size: 2em;
      font-weight: 700;
      color: $textColour;
      text-decoration: none;
      transition: 0.5s;
      transform-origin: right;
    }
    .toggle {
      position: relative;
      background: url("../../assets/menu.png");
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px;
      cursor: pointer;
      &.active {
        position: relative;
        background: url("../../assets/close.png");
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 25px;
      }
    }
  }
}
