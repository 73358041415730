@import "../variables";
@import "../functions";
@import "../animation";
@import "./modal";

@mixin dataCard {
  @include modal;
  .dataCard {
    position: relative;
    z-index: 5;
    transition: ease-in-out 0.5s;
    margin: 20px 10px;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 50%;
      bottom: 0;
      background: $shadeColor;
      transform: skew(0deg, -2deg);
      pointer-events: none;
    }
    &::before{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $boxAfterBg;
      pointer-events: none;
      transform: skew(0deg, -2deg);
      z-index: 1;
    }
    &:nth-child(1) {
      animation: dataCardAnimation ease-in 0.5s;
      .cardBox {
        &::before,
        &::after {
          background: $blurColor1;
        }
      }
    }
    &:nth-child(3) {
      animation: dataCardAnimation2 ease-in 1s;
      .cardBox {
        &::before,
        &::after {
          background: $blurColor2;
        }
      }
    }
    &:nth-child(5) {
      animation: dataCardAnimation3 ease-in 1.5s;
      .cardBox {
        &::before,
        &::after {
          background: $blurColor3;
        }
      }
    }
    &:nth-child(7) {
      animation: dataCardAnimation4 ease-in 2s;
      .cardBox {
        &::before,
        &::after {
          background: $blurColor4;
        }
      }
    }
    .cardBox {
      position: relative;
      height: 380px;
      width: 280px;
      @include flexDisplay;
      backdrop-filter: blur(25px);
      /*create blur effect to border*/
      &::before {
        position: absolute;
        content: "";
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        transform: skew(0deg, -2deg);
        z-index: -2;
        //filter: blur(10px);
      }

      &:hover {
        .cardContent {
          h2 {
            top: -130px;
          }
          a {
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .cardContent {
        position: relative;
        padding: 20px;
        transform: translateY(40px);
        h2 {
          position: absolute;
          color: $wmColor;
          top: -60px;
          right: 10px;
          margin: 0;
          padding: 0;
          font-size: 10em;
          transition: 0.5s;
          pointer-events: none;
        }
        h3 {
          margin: 0 0 10px 0;
          padding: 0;
          font-size: 24px;
          font-weight: 600;
          color: $textColour;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: $textColour;
        }
        a {
          position: relative;
          padding: 10px 20px;
          margin: 20px 0 0;
          background: transparent;
          text-decoration: none;
          border: 1px solid $textColour;
          display: inline-block;
          transition: 0.5s;
          color: $textColour;
          transform: translateY(-40px);
          opacity: 0;
          visibility: hidden;
          &:hover {
            color: $bgColour;
            background: $textColour;
          }
        }
      }
    }
  }
}

@include dataCardAnimation;
@include dataCardAnimation2;
@include dataCardAnimation3;
@include dataCardAnimation4;
