@import "./variables";

@mixin aboutLeftComein {
  @keyframes aboutLeftComein {
    0% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@mixin bgLeftComein {
  @keyframes bgLeftComein {
    0% {
      transform: skewX(45deg) translateX(-200%);
    }
    100% {
      transform: skewX(45deg) translateX(0);
    }
  }
}

@mixin aboutRightComein {
  @keyframes aboutRightComein {
    0% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@mixin bgRightComein {
  @keyframes bgRightComein {
    0% {
      transform: skewX(45deg) translateX(200%);
    }
    100% {
      transform: skewX(45deg) translateX(120px);
    }
  }
}

@mixin aboutCardComein1($skew: 45deg, $start: 300%, $end: $cardOneEnd) {
  @keyframes aboutCardComein1 {
    0% {
      transform: skewX($skew) translateX($start);
    }
    100% {
      transform: skewX($skew) translateX($end);
    }
  }
}

@mixin aboutCardComein2($skew: 45deg, $start: 300%, $end: $cardTwoEnd) {
  @keyframes aboutCardComein2 {
    0% {
      transform: skewX($skew) translateX($start);
    }
    100% {
      transform: skewX($skew) translateX($end);
    }
  }
}

@mixin aboutCardComein3($skew: 45deg, $start: 300%, $end: $cardThreeEnd) {
  @keyframes aboutCardComein3 {
    0% {
      transform: skewX($skew) translateX($start);
    }
    100% {
      transform: skewX($skew) translateX($end);
    }
  }
}

// dataCard animation
@mixin dataCardAnimation($start: -300%, $end: 0) {
  @keyframes dataCardAnimation {
    0% {
      transform: translateX($start);
    }
    100% {
      transform: translateX($end);
    }
  }
}

@mixin dataCardAnimation2($start: -300%, $end: 0) {
  @keyframes dataCardAnimation2 {
    0% {
      transform: translateY($start);
    }
    100% {
      transform: translateY($end);
    }
  }
}

@mixin dataCardAnimation3($start: 300%, $end: 0) {
  @keyframes dataCardAnimation3 {
    0% {
      transform: translateY($start);
    }
    100% {
      transform: translateY($end);
    }
  }
}

@mixin dataCardAnimation4($start: 300%, $end: 0) {
  @keyframes dataCardAnimation4 {
    0% {
      transform: translateX($start);
    }
    100% {
      transform: translateX($end);
    }
  }
}

@mixin cardTitleAnimation($start: -1000%, $end: 0) {
  @keyframes cardTitleAnimation {
    0% {
      transform: translateY($start);
    }
    100% {
      transform: translateY($end);
    }
  }
}

// frontend box animation
@mixin boxAnimation($start: -1000%, $end: 0) {
  @keyframes boxAnimation {
    0% {
      transform: translateY($start);
    }
    100% {
      transform: translateY($end);
    }
  }
}

@mixin backendLeftBgComein($start: -500%, $end: 0) {
  @keyframes backendLeftBgComein {
    0% {
      transform: translateX($start);
    }
    100% {
      transform: translateX($end);
    }
  }
}

@mixin backendRightBgComein($start: 500%, $end: 0) {
  @keyframes backendRightBgComein {
    0% {
      transform: translateX($start);
    }
    100% {
      transform: translateX($end);
    }
  }
}

@mixin bgAnimate {
  @keyframes bgAnimate {
    0%,
    100% {
      transform: translateY(15px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
}

@mixin contactBgComein {
  @keyframes contactBgComein {
    0% {
      transform: scale(0);
      transform-origin: bottom;
    }
    100% {
      transform: scale(1);
    }
  }
}

@mixin containerAnimation($start: -500%, $end: 0) {
  @keyframes containerAnimation {
    0% {
      transform: translateY($start);
    }
    100% {
      transform: translateY($end);
    }
  }
}
