@import "../variables";
@import "../functions";
@import "../animation";

@mixin modal {
  .dataCardModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    padding: 60px;
    background: rgba(225, 225, 225, 0.3);
    backdrop-filter: blur(60px);
    box-shadow: 0 0 0 100vh rgba(49, 39, 71, 0.95);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    z-index: 10000;
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      background: linear-gradient(
        to bottom,
        rgba(225, 225, 225, 0.3) 0%,
        rgba(225, 225, 225, 0.1) 15%,
        transparent 50%,
        transparent 85%,
        rgba(225, 225, 225, 0.3) 100%
      );
      pointer-events: none;
    }
    h2 {
      font-size: 40px;
      color: $textColour;
      //#312747
    }
    p {
      font-size: 20px;
      color: $textColour;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    &.active {
      visibility: visible;
      opacity: 1;
      z-index: 10000;
    }
  }
}
