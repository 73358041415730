@import "./scss/variables";
@import "./scss/functions";
@import "./scss/home/banner";
@import "./scss/home/header";
@import "./scss/home/title";
@import "./scss/home/btn";
@import "./scss/home/menu";
@import "./scss/home/sciIcon";
@import "./scss/home/block";
@import "./scss/about/about";
@import "./scss/about/content";
@import "./scss/about/trailer";
@import "./scss/dataAnalytics/data";
@import "./scss/dataAnalytics/dataCard.scss";
@import "./scss/frontend/frontend";
@import "./scss/backend/backend";
@import "./scss/contact/contact";
@import "./scss/contact/container";
@import "./scss/animation";
@import "./scss/media";

//home page
.banner {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bgColour;
  overflow: hidden;
  @include flexDisplay(column, center, center);
  @include bannerSudo;
  @include bannerSudoHover;
  @include header;
  @include title;
  @include btn;
  @include sciIcon;
  @include menu;
  @include block;
}

// about me page
.about {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: $bgColour;
  color: $textColour;
  padding: 80px 100px;
  @include flexDisplay(row, flex-start, center);
  @include about;
  @include header;
  @include menu;
  @include content;
  @include trailer;
}

// fronend UX/UI page
.frontend {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bgColour;
  overflow: hidden;
  color: $textColour;
  padding: 100px 0 0;
  @include header;
  @include menu;
  @include frontend;
}

// backend development page
.backend {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bgColour;
  overflow: hidden;
  color: $textColour;
  padding: 80px 100px 0;
  @include header;
  @include menu;
  @include flexDisplay(column, center, space-between);
  @include backend;
}

// data analytics page
.data-analytics {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bgColour;
  overflow: hidden;
  color: $textColour;
  padding: 80px 100px 0;
  @include data;
  @include flexDisplay(column, center, space-between);
  @include header;
  .content {
    @include flexDisplay(row, space-between, center);
    flex-wrap: wrap;
    @include dataCard;
  }
  @include menu;
}

.contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $bgColour;
  overflow: hidden;
  color: $textColour;
  padding: 0 100px 0;
  @include flexDisplay(row, center, center);
  flex-wrap: wrap;
  @include contact;
  @include header;
  @include menu;
}

@include media;
