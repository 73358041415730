@import "./variables";

@mixin flexDisplay($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin svgSetting($width: 150px, $height: 150px) {
  svg {
    position: relative;
    width: $width;
    height: $height;
    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: $circleBgColor;
      stroke-width: 8;
      stroke-linecap: round;
      transform: translate(5px, 5px);
    }
  }
}

@mixin bgEffect($bgcolour: $bgBeforeColour, $top: 0) {
  position: absolute;
  content: "";
  top: $top;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bgcolour;
}
