@import "../variables";
@import "../functions";

@mixin block {
  .block {
    position: absolute;
    width: 50px;
    height: 100px;
    background: $bgColour;
    box-shadow: $shadow;
    pointer-events: none;
    &:nth-child(even) {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    h3 {
      pointer-events: none;
      font-size: 1em;
      color: $bgTextColor;
      text-align: center;
    }
  }
}
