@import "../variables";
@import "../functions";
@import "../animation";

@mixin box {
  .box {
    position: relative;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    @include flexDisplay(column, center, center);
    transition: 0.5s ease-in-out;
    transform-style: preserve-3d;
    
    &:nth-of-type(1) {
      grid-column: 1/3;
      animation: boxAnimation ease-in-out 2.7s;
    }
    &:nth-of-type(2) {
      animation: boxAnimation ease-in-out 2.4s;
    }
    &:nth-of-type(3) {
      animation: boxAnimation ease-in-out 2.1s;
      .boxFront{
        background: $thirdBoxBg;
        h2, p{
          color:$bgColour;
        }
      }
    }
    &:nth-of-type(4) {
      animation: boxAnimation ease-in-out 1.8s;
      .boxFront{
        background: $thirdBoxBg;
        h2, p{
          color:$bgColour;
        }
      }
    }
    &:nth-of-type(5) {
      grid-column: 2/4;
      animation: boxAnimation ease-in-out 1.5s;
    }
    &:nth-of-type(6) {
      animation: boxAnimation ease-in-out 1.2s;
    }
    &:nth-of-type(7) {
      animation: boxAnimation ease-in-out 0.9s;
    }
    &:nth-of-type(8) {
      animation: boxAnimation ease-in-out 0.6s;
      .boxFront{
        background: $thirdBoxBg;
        h2, p{
          color: $bgColour;
        }
      }
    }
    &:nth-of-type(9) {
      grid-column: 3/5;
      animation: boxAnimation ease-in-out 0.3s;
    }
    .boxFront {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: ease-in-out 0.5s;
      @include flexDisplay(column, center, center);
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
      background: $themeBoxBg;
      h2 {
        font-size: 2.5em;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.2em;
        font-weight: 500;
      }
    }
    .boxBack {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: ease-in-out 0.5s;
      padding: 15px 20px;
      color: #ffffff;
      background: $boxBg;
      @include flexDisplay;
      /*showing the back face of card back*/
      transform: rotateY(180deg);
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
      p{
        font-size: 1em;
        font-weight: 400;
        color: $textColour;
      };
    }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 5px;
    //   left: 5px;
    //   right: 5px;
    //   bottom: 5px;
    //   background: $boxAfterBg;
    //   pointer-events: none;
    // }
    &:hover {
      transform: rotateY(180deg);
    }

  }
}

@include boxAnimation;
