@import "../variables";
@import "../functions";
@import "../animation";

@mixin animatedCard {
  .animatedCard {
    position: relative;
    max-width: 560px;
    height: 260px;
    margin: 20px 0 0 0;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    background: $cardBgColour;
    overflow: hidden;
    @include flexDisplay;
    border-top: $cardBorder;
    border-left: $cardBorder;
    backdrop-filter: blur(25px);
    h1 {
      position: absolute;
      font-size: 4em;
      color: $titleColour;
      transition: 0.3s;
      visibility: visible;
      opacity: 1;
    }
    &:hover {
      .content {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
      h1 {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-100px);
      }
    }
    .content {
      padding: 20px;
      text-align: center;
      transition: 0.5s;
      transform: translateY(100px);
      visibility: hidden;
      opacity: 0;

      h2 {
        position: absolute;
        top: -28%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 8em;
        color: $wmColour;
        pointer-events: none;
      }
      h3 {
        font-size: 1.8em;
        color: $textColour;
        z-index: 1;
        letter-spacing: 2px;
      }
      p {
        margin-top: 15px;
        font-size: 1em;
        color: $textColour;
        font-weight: 300;
      }
    }
  }
}
