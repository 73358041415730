@import "../variables";
@import "../functions";

@mixin title {
  h2 {
    color: $textColour;
    text-align: center;
    font-size: 12vw;
    line-height: 0.6em;
    z-index: 10;
    font-weight: 900;
    transition: 0.5s;
    transform: translateY(-30%);
    visibility: visible;
    opacity: 1;
    &::after {
      position: absolute;
      content: "to infinity and beyond";
      font-size: 0.1em;
      font-weight: 700;
      bottom: -32%;
      right: 1%;
      color: $primaryColor;
      z-index: 10;
    }
    span {
      font-size: 0.2em;
      letter-spacing: 0.4em;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
