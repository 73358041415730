@import "../variables";
@import "../functions";

// button colour effect
@mixin btnDeco($side: bottom) {
  position: absolute;
  content: "";
  left: 50%;
  @if $side == top {
    transform: skewX(45deg) translateX(-50%);
    top: -5px;
    background: $btnDeco;
  } @else {
    transform: skewX(45deg) translateX(-50%);
    bottom: -5px;
    background: $secondaryColor;
  }

  width: 30px;
  height: 10px;
  //border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}

// button hover colour effect
@mixin btnDecoHover($side: bottom) {
  @if $side == top {
    top: 1px;
  } @else {
    bottom: 1px;
  }
  height: 50%;
  width: 70%;
  border-radius: 30px;
  transition-delay: 0.2s;
}

@mixin btn {
  .btn {
    display: block;
    position: relative;
    width: 188px;
    height: 50px;
    margin: 20px;
    z-index: 10;
    transition: 0.5s;
    transform-origin: right;
    &::before {
      @include btnDeco;
    }
    &::after {
      @include btnDeco(top);
    }
    &:hover {
      a {
        letter-spacing: 3px;
        &::before {
          transform: skewX(45deg) translateX(200%);
        }
      }
      &::before {
        @include btnDecoHover;
      }
      &::after {
        @include btnDecoHover(top);
      }
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include flexDisplay;
      background: $btnBgColour;
      box-shadow: $btnShadow;
      border-top: $borderEdge;
      border-bottom: $borderEdge;
      border-radius: 30px;
      color: $textColour;
      text-decoration: none;
      font-weight: 400;
      z-index: 1;
      letter-spacing: 1px;
      overflow: hidden;
      transition: 0.5s;
      backdrop-filter: blur(15px);
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: $btnEffect;
        transform: skewX(45deg) translateX(0);
        transition: 0.5s;
      }
    }
  }
}
