@import "../variables";
@import "../functions";

@mixin trailer {
  .trailer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    video {
      max-width: 900px;
      outline: none;
      z-index: 1000;
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
    img.close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      max-width: 32px;
    }
  }
}
