@import "../variables";
@import "../functions";
@import "../animation";

@mixin about {
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: $btnEffect;
    transform: skewX(45deg) translateX(0);
    transition: 0.5s;
    animation: bgLeftComein 1s;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: $btnBgColour;
    transform: skewX(45deg) translateX(120px);
    transition: 0.5s;
    animation: bgRightComein 1s;
  }
  &.active {
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    .content {
      .profile {
        transform: translateX(-200%);
      }
      .skills {
        transform: translateX(200%);
      }
    }
    &::before {
      transform: skewX(45deg) translateX(200%);
    }
    &::after {
      transform: skewX(45deg) translateX(-200%);
    }
  }
}

@include bgLeftComein;
@include bgRightComein;
