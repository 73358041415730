@import "../variables";
@import "../functions";
@import "./box";

@mixin frontend {
  &.active {
    .menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .logo {
      transform: scale(0);
      transform-origin: left;
    }
    .box {
      transform: scale(0);
      transform-origin: left;
    }
  }
  .container {
    display: grid;
    /*Create 4 column and each column take up 1 fragment equally*/
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    perspective: 1200px;
    @include box;
  }
}
